@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.react-date-picker__wrapper {
  border: none ! important;
  color: #515151 ! important;
}

.react-date-picker__inputGroup {
  color: #222222 ! important;
  padding-top: 5px;
}
.react-date-picker__inputGroup__input {
  color: #222222 ! important;
}

.ck.ck-balloon-panel.ck-balloon-panel_visible {
  z-index: 25000;
}
.dGiqWE {
  color: #282828;
}
.iCYHlf {
  color: #282828;
}
.ck.ck-editor__editable_inline {
  color: #282828;
}
.react-date-picker__inputGroup {
    width: 137% ! important;
}
.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}

.Table__pagination {
  display: flex;
  justify-content: flex-end;
  /*padding: 20px 10px;*/
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: gray;
}

.Table__pageButton--active {
  color: #45b3e3;
  font-weight: bold;
}

.ReactTable .rt-thead.-header {
    box-shadow: none;
    background: #F5F5FD;
}
.react-date-picker__wrapper {
  border: none ! important;
  color: #515151 ! important;
}

.react-date-picker__inputGroup {
  color: #222222 ! important;
  padding-top: 5px;
}
.react-date-picker__inputGroup__input {
  color: #222222 ! important;
}
.ck .ck-balloon-panel .ck-balloon-panel_arrow_n .ck-balloon-panel_visible .ck-balloon-panel_with-arrow {
  top: 0px;
}
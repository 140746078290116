@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}

.Table__pagination {
  display: flex;
  justify-content: flex-end;
  /*padding: 20px 10px;*/
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: gray;
}

.Table__pageButton--active {
  color: #45b3e3;
  font-weight: bold;
}

.ReactTable .rt-thead.-header {
    box-shadow: none;
    background: #F5F5FD;
}
.react-date-picker__wrapper {
  border: none ! important;
  color: #515151 ! important;
}

.react-date-picker__inputGroup {
  color: #222222 ! important;
  padding-top: 5px;
}
.react-date-picker__inputGroup__input {
  color: #222222 ! important;
}
.ck .ck-balloon-panel .ck-balloon-panel_arrow_n .ck-balloon-panel_visible .ck-balloon-panel_with-arrow {
  top: 0px;
}
.Dropzone {
  /*height: 50px;
  width: 100%;*/
  background-color: #f7f7f7;
  /*border: 2px dashed rgb(187, 186, 186);*/
  /*border-radius: 50%;*/
  /*display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;*/
  font-size: 12px;
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none ! important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.react-date-picker__wrapper {
  border: none ! important;
  color: #515151 ! important;
}

.react-date-picker__inputGroup {
  color: #222222 ! important;
  padding-top: 5px;
}
.react-date-picker__inputGroup__input {
  color: #222222 ! important;
}

.ck.ck-balloon-panel.ck-balloon-panel_visible {
  z-index: 25000;
}
.dGiqWE {
  color: #282828;
}
.iCYHlf {
  color: #282828;
}
.ck.ck-editor__editable_inline {
  color: #282828;
}
.react-date-picker__inputGroup {
    width: 137% ! important;
}
.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}

.Table__pagination {
  display: flex;
  justify-content: flex-end;
  /*padding: 20px 10px;*/
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: gray;
}

.Table__pageButton--active {
  color: #45b3e3;
  font-weight: bold;
}

.ReactTable .rt-thead.-header {
    box-shadow: none;
    background: #F5F5FD;
    color: #222222;
}
.ReactTable .rt-td {
  color:#222222;
}
.react-toggle {
    touch-action: pan-x;
  
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
  
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transition: opacity 0.25s;
  }
  
  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #e7f0f7;
    transition: all 0.2s ease;
  }
  
  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #e7f0f7;
  }
  
  .react-toggle--checked .react-toggle-track {
    background-color: #11aba6;
  }
  
  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #11aba6;
  }
  
  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }
  
  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;
    box-sizing: border-box;
    transition: all 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19AB27;
  }
  
  .react-toggle--focus .react-toggle-thumb {
    box-shadow: 0px 0px 2px 3px #0099E0;
  }
  
  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: 0px 0px 5px 5px #0099E0;
  }
.css-wsp0cs-MultiValueGeneric {
    font-weight: 600;
}

